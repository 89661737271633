<template>
  <base-view
    title="Presupuesto federal"
    description="Importación de layout para control del presupuesto federal."
    header-type="overlap"
    icon="calendar"
  >
    <x-card-wizard>
      <template #navigation>
        <wizard-header-step
          v-for="tab in tabs"
          :key="tab.id"
          :item="tab"
          :active="selected.name === tab.name"
          :disabled="false"
        ></wizard-header-step>
      </template>

      <div>
        <!-- Importación -->
        <x-card-wizard-body
          v-if="selected.name === 'importacion'"
          title="Importación"
          description="Selecciona el archivo layout para su procesamiento."
          :cols="12"
          :lg="8"
        >
          <import namespaces="presupuestoFederalModule"></import>

          <template #buttonNext>
            <check-authorization
              :requiresAuthorizations="[$route.meta.permissions.update]"
              :show-alert="false"
              no-slots
              #default="{ authorized, message }"
            >
              <span v-b-tooltip.top :title="message">
                <button
                  @click="$isAuthorized(authorized, goToEdicion)"
                  class="btn btn-primary"
                  :disabled="!authorized || !importacion.processed"
                >
                  Editar presupuesto
                </button>
              </span>
            </check-authorization>
          </template>
        </x-card-wizard-body>

        <!-- Proyección -->
        <x-card-wizard-body
          v-if="selected.name === 'edicion'"
          title="Edición"
          description="Consulta y modifica la información importada."
          :cols="12"
        >
          <div class="row justify-content-center" style="min-height: 50px">
            <div class="col">
              <b-overlay :show="loading">
                <template #overlay>
                  <div class="text-center">
                    <loading
                      message="Ejecutando algoritmo para la separación de importes, por favor espere..."
                    ></loading>
                  </div>
                </template>
                <edicion namespaces="presupuestoFederalModule"></edicion>
              </b-overlay>
            </div>
          </div>

          <template #buttonPrev>
            <button class="btn btn-light" @click="backToImportes" :disabled="loading">
              Anterior
            </button>
          </template>
        </x-card-wizard-body>
      </div>
    </x-card-wizard>
  </base-view>
</template>

<script>
import { mapState } from 'vuex'
import WizardHeaderStep from '@/components/Shared/WizardHeaderStep'

import Import from '@/components/FederalYAmpliacion/Import'
import Edicion from '@/components/FederalYAmpliacion/Edicion'

export default {
  name: 'PresupuestoFederalImportView',

  components: {
    Import,
    Edicion,
    WizardHeaderStep
  },

  props: {
    tabIndex: {
      type: Number,
      required: true,
      default: 0
    }
  },

  created () {
    if (!this.selected) {
      this.$store.commit('presupuestoFederalModule/setTab', this.tabs[0])
    }
  },

  data () {
    return {
      loading: false,

      tabs: [
        {
          id: 1,
          name: 'importacion',
          label: 'Importación',
          description: 'Importa el layout'
        },
        {
          id: 2,
          name: 'edicion',
          label: 'Edición',
          description: 'Consulta y modifica la información importada'
        }
      ]
    }
  },

  computed: {
    ...mapState('presupuestoFederalModule', ['selected', 'importacion', 'recursos'])
  },

  methods: {
    goToEdicion () {
      this.$store.commit('presupuestoFederalModule/setTab', this.tabs[1])
    },

    backToImportes () {
      this.$store.commit('presupuestoFederalModule/setTab', this.tabs[0])
    }
  }
}
</script>
